<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">How can we help?</h4>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-radio-group v-model="orderTypeRadioGroup">
        <v-radio label="Processing & Scanning" value="ps"></v-radio>
        <v-radio label="Processing Only" value="p"></v-radio>
        <v-radio label="Scanning Only" value="s"></v-radio>
      </v-radio-group>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          right
          plain
          outlined
          :disabled="orderTypeRadioGroup === null"
          @click="nextStep(2)"
        >
          Next
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'staet',
  title: 'Start',
  components: {},
  data: function () {
    return {
      orderTypeRadioGroup: null
    }
  },
  computed: {},
  async created () {},
  methods: {
    startOver () {
      this.$emit('startOver')
    },
    nextStep (n) {
      this.$emit.nextStep(n)
    },
    onOrderTypeChange () {
      this.$emit.onOrderTypeChange(this.orderTypeRadioGroup)
    }
  }
}
</script>
<style lang="scss">
</style>
